<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-file-document-box-multiple-outline"
          :title="$t(title)"
        >
          <studios-form
            :studio="studio"
            @validated="updateStudio()"
          ></studios-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StudiosForm from "@/components/studio/Form";
export default {
  name: "StudioEdit",
  components: { StudiosForm },
  mounted() {
    this.fetchStudio();
  },
  data() {
    return {
      studio: null,
    };
  },
  methods: {
    ...mapActions("studios", ["getStudio", "editStudio"]),
    fetchStudio() {
      this.getStudio(this.$route.params.id).then((response) => {
        this.studio = response;
        let ts = this.studio.tattooer_studios.find((q) => {
          return q.tattooer_id == this.$store.state.auth.user.tattooer.id;
        });
        this.studio.comment = ts.comment;
      });
    },
    updateStudio() {
      this.editStudio({
        studio_id: this.$route.params.id,
        studio: this.studio,
      }).then((response) => {
        if (response.success) {
          this.errors.clear();
          this.$alert(this.$t("studio_edit_success"), "", "");
        } else {
          this.$store.commit(
            "app/SET_NOTIFICATION",
            { message: response, show: true, color: "error" },
            { root: true }
          );
        }
        this.$router.back();
      });
    },
  },
};
</script>