<template>
  <form
    action
    data-vv-scope="studio-form"
    @submit.prevent="validateForm('studio-form')"
    v-if="studio"
    id="studioEdit"
  >
    <v-btn
      color="primary"
      @click="$router.back()"
      style="height: 30px; margin-bottom: 30px"
      outlined
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t("back") }}
    </v-btn>
    <h3
      style="
        text-transform: uppercase;
        text-decoration: underline;
        color: var(--v-primary-base);
      "
      class="mt-2"
    >
      {{ $t("studios.info") }}
    </h3>
    <v-row>
      <v-col cols="12" md="4" class="py-0">
        <label>{{ $t("studios.name") }}</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="studio.studio_name"
          data-vv-validate-on="blur"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('studio-form.studio_name')"
          color="secondary"
          :disabled="studio.user.role_id !== 9 && studio.main_studio"
          data-vv-name="studio_name"
        />
      </v-col>
      <v-col cols="12" md="8" class="py-0">
        <label>{{ $t("studios.address") }}</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="studio.address"
          :disabled="studio.user.role_id !== 9 && studio.main_studio"
          data-vv-validate-on="blur"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('studio-form.address')"
          color="secondary"
          data-vv-name="address"
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <label>{{ $t("studios.postal_code") }}</label>
        <v-text-field
          outlined
          dense
          hide-details
          :disabled="studio.user.role_id !== 9 && studio.main_studio"
          v-model="studio.postal_code"
          data-vv-validate-on="blur"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('studio-form.postal_code')"
          color="secondary"
          data-vv-name="postal_code"
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <label>{{ $t("studios.city") }}</label>
        <v-text-field
          outlined
          dense
          hide-details
          :disabled="studio.user.role_id !== 9 && studio.main_studio"
          v-model="studio.city"
          data-vv-validate-on="blur"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('studio-form.city')"
          color="secondary"
          data-vv-name="city"
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <label>{{ $t("studios.country") }}</label>
        <country-select
          :edit="!(studio.user.role_id !== 9 && studio.main_studio)"
          :value="studio"
          valueKey="country"
          :error_messages="errors.collect('studio-form.country')"
          vv_name="country"
        ></country-select>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col
        class="py-0"
        cols="12"
        md="4"
        v-if="$store.getters['auth/isTattooer']"
      >
        <label>{{ $t("studios.comment") }}</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="studio.comment"
          data-vv-validate-on="blur"
          v-validate="''"
          :error-messages="errors.collect('studio-form.comment')"
          color="secondary"
          data-vv-name="comment"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <h3
      style="
        text-transform: uppercase;
        text-decoration: underline;
        color: var(--v-primary-base);
      "
      class="mt-5"
    >
      {{ $t("studios.contact") }}
    </h3>
    <v-row>
      <v-col cols="12" class="py-0">
        <user-form
          :disabled="studio.user.role_id !== 9 && studio.main_studio"
          ref="user_form"
          :user="studio.user"
          :buttons="false"
          :password="false && studio.user.role_id === 9"
        />
      </v-col>
    </v-row>

    <h3
      v-if="studio.user.role_id === 9"
      style="
        text-transform: uppercase;
        text-decoration: underline;
        color: var(--v-primary-base);
      "
      class="mt-5"
    >
      {{ $t("Usuario") }}
    </h3>
    <v-row v-if="studio.user.role_id === 9">
      <v-col class="py-0" cols="12" sm="6" md="3">
        <label>{{ $t("email") }}</label>

        <v-text-field
          outlined
          hide-details
          dense
          v-model="studio.user.email"
          data-vv-validate-on="blur"
          v-validate="'email'"
          :error-messages="errors.collect('user-form.email')"
          color="primary"
          data-vv-name="email"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        sm="6"
        md="3"
        v-if="!isCustomer && password !== false"
      >
        <label>{{ $t("password") }}</label>
        <v-text-field
          outlined
          hide-details
          :disabled="disabled"
          dense
          v-model="studio.user.password"
          data-vv-validate-on="blur"
          v-validate="skip_validate ? '' : 'required'"
          :error-messages="errors.collect('user-form.password')"
          color="primary"
          data-vv-name="password"
          :append-icon="show.password ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="show.password = !show.password"
          :type="show.password ? 'text' : 'password'"
        />
      </v-col>
      <v-col
        class="py-0"
        sm="6"
        cols="12"
        md="3"
        v-if="!isCustomer && password !== false"
      >
        <label>{{ $t("password2") }}</label>
        <v-text-field
          outlined
          hide-details
          :disabled="disabled"
          dense
          v-model="studio.user.password_repeat"
          data-vv-validate-on="blur"
          v-validate="'is:' + studio.user.password"
          :error-messages="errors.collect('user-form.password_repeat')"
          data-vv-name="password_repeat"
          color="primary"
          :append-icon="show.password_repeat ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="show.password_repeat = !show.password_repeat"
          :type="show.password_repeat ? 'text' : 'password'"
          :append-outer-icon="
            studio.user.password_repeat
              ? studio.user.password_repeat == studio.user.password
                ? 'mdi-check'
                : 'mdi-close'
              : ''
          "
        />
      </v-col>
    </v-row>
    <h3
      style="
        text-transform: uppercase;
        text-decoration: underline;
        color: var(--v-primary-base);
      "
      class="d-inline-block"
    >
      {{ $t("timetable", { name: "Por defecto" }) }}
    </h3>
    <v-tooltip>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon style="text-decoration: unset"
          ><v-icon>mdi-information-outline</v-icon></v-btn
        >
      </template>
      Para configurar diferentes horarios para diferentes días lo podrás hacer
      después desde la configuración del estudio
    </v-tooltip>
    <v-row>
      <v-col cols="12" class="py-0">
        <timetable
          :disable="studio.user.role_id !== 9 && studio.main_studio"
          :timetable="studio.timetable"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="buttons">
      <v-col cols="2">
        <v-btn
          color="primary"
          type="submit"
          style="
            width: 130px !important;
            color: #363533;
            height: 30px;
            padding-top: 10px !important;
            margin-left: 10px;
          "
          elevation="0"
        >
          <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "StudiosForm",
  components: {
    UserForm: () => import("@/components/user/Form"),
    Timetable: () => import("@/components/ui/Timetable"),
    CountrySelect: () => import("@/components/ui/CountrySelect"),
    SocialNetworks: () => import("@/components/ui/SocialNetworks"),
  },
  props: {
    studio: { type: Object, required: true },
    buttons: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      show: { password: false, password_repeat: false },
    };
  },
  watch: {
    "studio.studio_name": {
      handler() {
        this.generateToken();
      },
      deep: true,
    },
  },
  methods: {
    generateToken() {
      if (this.studio.studio_name) {
        let token = this.studio.studio_name;

        token = token.toLowerCase().replace(/ /g, "_");

        this.studio.token = token;
      }
    },
    async validateForm(scope = "studio-form") {
      this.errors.clear();
      this.userProcessed = false;
      let user_val = await this.$refs.user_form.validateForm("user-form");
      let form_val = await this.$validator.validateAll(scope);
      if ((form_val && user_val) || this.$store.getters["auth/isTattooer"]) {
        this.$emit("validated", this.customer);
        //////console.log("validated");
        return true;
      }
      return false;
    },
  },
  computed: {
    URL() {
      return window.location.origin;
    },
  },
};
</script>
<style lang="sass">
#studioEdit
  label
    display: block
    height: 15px
    line-height: 1
</style>